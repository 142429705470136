import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { Layout } from "../components";
import { getContent } from "../helper";
import { ForSaleLayout } from "../layouts/ForSale";

const ForSale = props => {
  const data = getContent(props?.data);

  return (
    <Layout title="For Sale" {...props}>
      {" "}
      <Helmet>
        <meta
          name="description"
          content="Adorable tiny Shih Tzu puppies and Imperial Shih Tzu puppies for sale now! Come see your newest family member while still available!"
        />{" "}
        <meta
          name="keywords"
          content="Imperial Shih Tzu Puppies for Sale, shih tzu puppies for sale los angeles, shih tzu for sale san diego, Teacuo Shih Tzu Puppies For Sale, AKC Shih Tzu Puppies for sale, Shih Tzu Puppies for Sale, Tiny Shih Tzu Puppies for sale, Shih Tzu Puppies for sale in Fullerton, Shih Tzu Puppies for sale in Irvine, Shih Tzu Puppies for sale in Newport Beach, Shih Tzu Puppies for sale in Los Angeles, Shih Tzu Puppies for sale in Mission Viejo, Shih Tzu Puppies for sale in Lake Forest, Shih Tzu Puppies for sale in Brea, Shih Tzu Puppies For Sale in La Habra Heights, Shih Tzu Puppies for Sale in Costa Mesa, Shih Tzu Puppies for sale in Las Vegas, Shih Tzu Puppies for Sale in Anaheim Hills"
        />
      </Helmet>
      <ForSaleLayout {...data} />
    </Layout>
  );
};

export default ForSale;

export const query = graphql`
  query {
    allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/for-sale/" } }) {
      nodes {
        html
        frontmatter {
          title
          puppies {
            name
            pictures {
              picture
            }
            price
            about
          }
          contact
          content
          selling
        }
      }
    }
  }
`;
