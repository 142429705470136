import React from "react";

import "./style.scss";
import { getImage, parseToHtml } from "../../helper";
import { Contact, Squiggle } from "../../components";

export const ForSaleLayout = ({
  title = "",
  puppies = [],
  html = "",
  content = "",
  contact = "",
  selling = false,
}) => (
  <div className="forSale">
    {title && <h1>{title}</h1>}
    <div className="data">
      {content || html
        ? parseToHtml(content || html)
        : null}
    </div>
    {selling && puppies?.length ? (
      <div className="puppies">
        {puppies?.map((puppy, index, row) => (
          <div className="puppy" key={index}>
            {puppy?.name && <h4>{puppy?.name}</h4>}
            {puppy?.pictures?.length ? (
              <div className="img">
                {puppy?.pictures?.map((picture, picIndex) => (
                  <img
                    key={picIndex}
                    src={getImage(picture?.picture)}
                    alt={puppy?.name}
                  />
                ))}
              </div>
            ) : null}
            {puppy?.price && <p>Price: {puppy?.price}</p>}
            {puppy?.about && <p>{puppy?.about}</p>}
            {(row.length === index + 1) ? null : <Squiggle />}
          </div>
        ))}
      </div>
    ) : (
      <div className="data">
        {content || html
          ? null
          : "All of our beautiful puppies have been sold. Please check back later, or contact me for more updated information."}
      </div>
    )}
    <Contact image={contact} />
  </div>
);
